import * as React from "react"
import type { HeadFC } from "gatsby"
import { graphql } from "gatsby"

import Headers from '../components/Headers'

import Layout from "../components/Layout"

import Crisis from "../components/science/Crisis"
import Research from "../components/science/Research"
import Tech from "../components/science/Tech"
import ModelOfChange from "../components/science/ModelOfChange"

export const query = graphql`
  query SciencePageQuery($lang: String!) {
    markdownRemark(
      fields: {lang: {eq: $lang}}
      fileAbsolutePath: {regex: "/science/header.md$/"}
    ) {
      frontmatter {
        title
        description
      }
      html
    }
  }
  `

const IndexPage = ({data, pageContext}: any) => {

  const sourcesLabel = pageContext.lang == 'en' ? 'Sources' : 'Quellen'
  data = data.markdownRemark

  return (
    <Layout lang={pageContext.lang}>
        <div className="science">
              <div style={{flex:1}}>
              <h1>{data.frontmatter.title}</h1>
              <p>{ data.frontmatter.description }</p>
              </div>
        </div>
        <Research lang={pageContext.lang} />
        <Crisis lang={pageContext.lang}/>
        <Tech lang={pageContext.lang} />
        <ModelOfChange lang={pageContext.lang} />
        <div className="sources">
          { /* APA citation style preferred */}
                <p>{sourcesLabel}:</p>
                <ul>
                  <li>
                      [1] World Health Organization (2022). World Mental Health Report: <a
                            href="https://www.who.int/news/item/17-06-2022-who-highlights-urgent-need-to-transform-mental-health-and-mental-health-care"
                            target="_blank">
                        WHO highlights urgent need to transform mental health and mental health care
                        </a>
                    </li>
                  <li>
                  [2] Marie-Carmen Neipp & Mark Beyebach (2022) The Global Outcomes of Solution-Focused Brief Therapy: A Revision, The American Journal of Family Therapy,
                    DOI:
                        <a
                            href="https://doi.org/10.1080/01926187.2022.2069175"
                            target="_blank">
                        10.1080/01926187.2022.2069175
                        </a>
                    </li>
                    <li>
                    [3] Harith, S., Backhaus, I., Mohbin, N., Ngo, H. T., & Khoo, S. (2022).
                    Effectiveness of digital mental health interventions for university students: an umbrella review.
                    PeerJ, 10, e13111. <a
                            href="https://doi.org/10.7717/peerj.13111"
                            target="_blank">
                        https://doi.org/10.7717/peerj.13111
                        </a>
                    </li>
                </ul>
            </div>
    </Layout>
  )
}

export default IndexPage

// add headers
export const Head: HeadFC = ({ location, params, data, pageContext }: any ) => {
    return <Headers
      lang={pageContext.lang}
      title={data.markdownRemark.frontmatter.title}
      description={data.markdownRemark.frontmatter.description}
      location={location}
      />
}
