import * as React from "react"
import { langProps } from "../types";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { useStaticQuery, graphql } from "gatsby"
import BottomImage from '../../images/mental.png'

export default (props: langProps) => {
    const results = useStaticQuery(
        graphql`{
          allMarkdownRemark(limit: 2, filter: {fileAbsolutePath: {regex: "/science/crisis.md$/"}}) {
            edges {
              node {
                frontmatter { lang }
                html
              }
            }
          }
        }`).allMarkdownRemark.edges

      let index = 0
      let result = results[index].node
      if(result.frontmatter.lang != props.lang) {
        result = results[++index].node
      }

    return (
        <div className="banner brown">
            <FontAwesomeIcon icon={faTriangleExclamation} size="2x" />
            <div dangerouslySetInnerHTML={{ __html: result.html }} />
            <img src={BottomImage} alt="Worldwide Mental Health Crisis" style={{marginBottom: -5}}/>
        </div>
    )
}