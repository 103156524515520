import * as React from "react"
import { langProps } from "../types"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
import { useStaticQuery, graphql } from "gatsby"
import iapLogoDe from "../../images/science/iap_de.png"
import iapLogoEn from "../../images/science/iap_en.svg"

import FadeInSection from "../FadeInSection"

export default (props: langProps) => {

    const results = useStaticQuery(
        graphql`{
          allMarkdownRemark(limit: 2, filter: {fileAbsolutePath: {regex: "/science/research.md$/"}}) {
            edges {
              node {
                frontmatter { lang }
                html
              }
            }
          }
        }`).allMarkdownRemark.edges

        let index = 0
        let result = results[index].node
        if(result.frontmatter.lang != props.lang) {
          result = results[++index].node
        }

        let iapLink = "https://www.zhaw.ch/en/psychology/institutes/iap/"
        if(props.lang=='de') {
          iapLink = "https://www.zhaw.ch/de/psychologie/institute/iap/"
        }
        const iapLogo = props.lang=='de' ? iapLogoDe : iapLogoEn

    return (
        <div className="banner grey">
            <FontAwesomeIcon icon={faLightbulb} size="2x" color="#444"/>
            <div dangerouslySetInnerHTML={{ __html: result.html }} />
            <FadeInSection>
              <a href={iapLink} target="_blank">
                <img src={iapLogo} alt="IAP" width={280} style={{marginTop: 60}}/>
              </a>
            </FadeInSection>
        </div>
    )
}