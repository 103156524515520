import * as React from "react"
import { langProps } from "../types"

import { useStaticQuery, graphql } from "gatsby"

export default (props: langProps) => {

    const results = useStaticQuery(
        graphql`{
          allMarkdownRemark(limit: 2, filter: {fileAbsolutePath: {regex: "/science/modelofchange.md$/"}}) {
            edges {
              node {
                frontmatter { lang }
                html
              }
            }
          }
        }`).allMarkdownRemark.edges

        let index = 0
        let result = results[index].node
        if(result.frontmatter.lang != props.lang) {
          result = results[++index].node
        }


    return (
        <div className="banner grey">
            <div dangerouslySetInnerHTML={{ __html: result.html }} />
        </div>
    )
}