import * as React from "react"
import { langProps } from "../types"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { useStaticQuery, graphql } from "gatsby"
import PlatformIcons from "../../images/ios_android_lightgrey.png"
import FadeInSection from "../FadeInSection"

export default (props: langProps) => {
    const results = useStaticQuery(
        graphql`{
          allMarkdownRemark(limit: 2, filter: {fileAbsolutePath: {regex: "/science/tech.md$/"}}) {
            edges {
              node {
                frontmatter { lang }
                html
              }
            }
          }
        }`).allMarkdownRemark.edges

        let index = 0
        let result = results[index].node
        if(result.frontmatter.lang != props.lang) {
          result = results[++index].node
        }

    return (
        <div className="banner blue">
            <FontAwesomeIcon icon={faMobileAlt} size="2x"/>
            <div dangerouslySetInnerHTML={{ __html: result.html }} />
            <FadeInSection speed="fast">
              <div style={{marginTop: '100px', opacity: '0.7'}}>
                <img src={PlatformIcons} width={200} alt="ephoria app for ios and android"/>
              </div>
            </FadeInSection>
        </div>
    )
}